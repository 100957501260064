:root {
  --off-white: #fafafa;
}

.nav {
  /* background-color: var(--off-white); */
  background-color: white;
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  font-family: "Karla", sans-serif;
  /* font-family: "Baloo Tammudu 2"; */
  /* font-weight: bold; */
}

.nav-logo {
  height: 60px;
  width: 100px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 1300px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-item {
  display: inline;
  margin-left: 2rem;
  color: black;
}

.nav-items {
  color: red;
}

.nav-item:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.section {
  color: white;
  background-color: #2f2fa2;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  height: 1000px;
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

.about-content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px;
  color: black;
  background-color: white;
  padding-top: 0px;
}

.aboutSection {
  color: white;
  background-color: white;
  font-family: "Karla", sans-serif;
  /* font-weight: bold; */
  height: 100%;
}

.aboutHeader {
  font-size: 120px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.aboutHeader span {
  color: red;
}

.aboutParagraph {
  font-size: 35px;
  margin-bottom: 100px;
}

.aboutParagraph span {
  color: red;
  font-weight: bold;
}

.videoLink {
  text-decoration: underline;
}

.projects-content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px;

  font-family: "Baloo Tammudu 2";
}

.card {
  width: 45%;
  background-color: white;

  margin-top: 5%;
  box-shadow: 0 5px 15px 0px rgba(236, 17, 17, 0.6);
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  overflow: hidden;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.projectsSection {
  background-color: white;
  color: black;
}

.projectParagraph {
  font-size: 35px;
}

.projectImage {
  width: 100%;
  border: 3px solid white;
}

.projectCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 10%;
  width: 100%;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.projectsHeaderContainer {
  display: flex;
  justify-content: center;
}

.contact-content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px;
}

.githubLogo {
  height: 30px;
}

.contactSection {
  color: black;
  background-color: white;
  font-family: "Karla", sans-serif;
  /* font-weight: bold; */
  height: 100px;
}

.projectTitle {
  font-size: 75px;

  /* everything under this makes the line */
  width: 100%;
  text-align: center;
  border-bottom: 3px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.projectTitle:hover {
  font-size: 80px;
  color: red;
}

.contactTitle {
  font-size: 75px;

  width: 100%;
  text-align: center;
  border-bottom: 3px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.contactTitle:hover {
  font-size: 80px;
  color: red;
}

.contactParagraph {
  font-size: 35px;
  padding-top: 40px;
  width: 90%;
}

.profileContainer {
  width: 600px;
  height: 500px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
}

.profileContainer img {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  border: 3px solid white;
}

.headerContent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.projectCardHeader {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 2px solid white;
  align-items: center;
}

.projectCardTitle {
  margin-right: 1px;
}

.socialLink:hover {
  color: red;
  text-decoration: underline;
  font-weight: bold;
}

.socialLink {
  color: black;
  text-decoration: none;
}

.contactLinks {
  margin-top: 250px;
  font-size: 25px;
  display: flex;
  justify-content: space-evenly;
}

.contactParagraph span {
  color: red;
}
@media only screen and (max-width: 600px) {
  .aboutHeader {
    font-size: 80px;
    height: 360px;
  }

  .projectCards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 10%;
    width: 100%;
  }

  .card {
    width: 100%;
    background-color: white;

    margin-top: 5%;
    box-shadow: 0 5px 15px 0px rgba(236, 17, 17, 0.6);
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
    overflow: hidden;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .contactParagraph {
    width: 100%;
  }
  .contactLinks {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .nav {
    width: 100%;
  }

  .nav-logo {
    display: none;
  }

  .App {
    width: 100%;
  }

  .redContact {
    color: red;
    font-weight: bold;
  }
}

@media only screen and (max-width: 750px) {
  .nav {
    width: fixed;
  }
}

.directLink {
  color: red;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
}
